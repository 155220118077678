import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { User } from "../models/User";


@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private router: Router, private http: HttpClient) {}

  activate(u: User): Observable<any> {
    return this.authenticate$(u.email, u.password);
  }

  authenticate$(email: string, password: string): Observable<{ token: string; refreshToken: string }> {
    return this.http.post<{ token: string; refreshToken: string }>(`${environment.api}/login_check`, {
      email,
      password,
    });
  }

  authenticateSso$(token: string): Observable<{ token: string; refreshToken: string; redirect: string }> {
    return this.http.get<{ token: string; refreshToken: string; redirect: string }>(
      `${environment.api}/security/sso/${token}`,
      {}
    );
  }

  refreshToken$(refreshToken: string): Observable<any> {
    // console.log("Envoi de la requête");
    return this.http.post(`${environment.api}/login_refresh`, { refreshToken });
  }

  /**
   * FIXME : Le back retourne une 401 token expired
   */
  passwordLost$(email: string): Observable<any> {
    return this.http.post(`${environment.api}/security/forgot-password`, { email });
  }

  /**
   * Get route authorization DocuSign
   */
  getRouteDocuSign$(): Observable<string> {
    return this.http.get(`${environment.api}/docusign/authorization`, { responseType: "text" });
  }
}
