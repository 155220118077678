<a *ngIf="!isPhone" mat-raised-button color="primary" [routerLink]="route" class="btn-text-icon">
  <i class="mr-small {{ icon }}"></i>
  <span>{{ label | transloco }}</span>
</a>

<a *ngIf="isPhone" mat-raised-button color="primary" [href]="route" class="btn-text-icon">
  <i class="mr-small {{ icon }}"></i>
  <!-- <span>{{ label | transloco }}</span> -->
  <span>{{ label }}</span>
</a>
