<app-header
  [isManager]="isManager$ | async"
  [isAdmin]="isAdmin$ | async"
  [isClient]="isClient$ | async"
  [user]="userData"
  [userAdmin]="userAdmin"
  [alertsCount]="alertsCount$ | async"
  (disconnect)="disconnect()"
  (disconnectAdmin)="disconnectAdmin()"
></app-header>

<div class="scroll-container" #scroller>
  <app-subheader *ngIf="!!subheaderTitle" [title]="subheaderTitle"></app-subheader>
  <div class="page-container-relative">
    <div class="page-container container" #pageContainer>
      <router-outlet></router-outlet>
    </div>
  </div>

  <!-- Back history navigation -->
  <a mat-raised-button color="primary" class="d-none d-sm-flex history-back btn-text-icon" (click)="back()">
    <span>Retour</span>
    <i class="icon-arrow-left"></i>
  </a>

  <!-- Declare prospect (Partner / Manager / Admin) -->
  <app-link-float
    *ngIf="(isDealer$ | async) || (isManager$ | async) || (isAdmin$ | async)"
    route="/dealer/prospects/declare"
    label="prospects.new"
  ></app-link-float>

  <!-- Access contacts -->
  <app-link-float
    *ngIf="isClient$ | async"
    route="tel:{{ contactPhone }}"
    label="Contact : {{ contactPhone }}"
    icon="icon-phone"
    [isPhone]="true"
  ></app-link-float>

  <app-footer [contacts]="contacts"></app-footer>
</div>

<a class="d-flex d-sm-none back-mobile" (click)="back()">
  <i class="icon-arrow-left"></i>
  <span>Retour</span>
</a>