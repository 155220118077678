import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngxs/store";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, filter, switchMap, take, tap } from "rxjs/operators";
import { DialogDocusignTokenService } from "../components/dialog-docusign-token/dialog-docusign-token.service";
import { AuthService } from "../services/auth.service";
import { AuthState, RefreshToken, Signout } from "../states/auth.state";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private refreshTokenInProgress = false;

  constructor(
    private store: Store,
    private authService: AuthService,
    private router: Router,
    private dialogDocusignService: DialogDocusignTokenService,
    private toastrService: ToastrService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.store.selectSnapshot(AuthState.token);
    let modifiedRequest = null;
    if (!req.url.startsWith("https://api-adresse.data.gouv.fr")) {
      modifiedRequest = req.clone({
        headers: req.headers.set("Authorization", token ? "Bearer " + token : ""),
      });
    } else {
      modifiedRequest = req.clone();
    }
    return next.handle(modifiedRequest).pipe(
      catchError((error: HttpErrorResponse, caught) => {
        if (error.status === 428) {
          this.dialogDocusignService.openModal$();
          return throwError(error);
        }
        if (error.status !== 401) {
          return throwError(error);
        }

        if (this.refreshTokenInProgress) {
          return this.refreshTokenSubject.pipe(
            filter(result => result !== null),
            take(1),
            switchMap(() => next.handle(this.addAuthenticationToken(modifiedRequest)))
          );
        } else {
          const refreshToken = this.store.selectSnapshot(AuthState.refreshToken);
          if (refreshToken) {
            this.refreshTokenInProgress = true;
            // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
            this.refreshTokenSubject.next(null);
            // Call auth.refreshAccessToken(this is an Observable that will be returned)
            // console.log("L'interceptor envoie l'action");
            return this.store.dispatch(new RefreshToken()).pipe(
              catchError(err => {
                // console.log("Interceptor error", error);
                this.refreshTokenInProgress = false;
                this.store.dispatch(new Signout()).pipe(tap(() => this.router.navigate(["/login"])));
                return throwError(err);
              }),
              switchMap(state => {
                // console.log("Interceptor success", state);
                this.refreshTokenInProgress = false;
                this.refreshTokenSubject.next(state.auth.refreshToken);
                return next.handle(this.addAuthenticationToken(modifiedRequest));
              })
            );
          } else {
            return throwError(error);
          }
        }
      })
    );
  }

  addAuthenticationToken(request: HttpRequest<any>): any {
    // Get access token from Local Storage
    const accessToken = this.store.selectSnapshot(AuthState.token);
    // If access token is null this means that user is not logged in
    // And we return the original request
    if (!accessToken) {
      return request;
    }
    // We clone the request, because the original request is immutable
    return request.clone({
      setHeaders: {
        Authorization: "Bearer " + accessToken,
      },
    });
  }
}
